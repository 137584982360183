import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import BehindTheScene from "../../components/BehindTheScene";
import Steps from "../../components/Steps";
import ContactForm from "../../components/ContactForm";
import { HeroHp } from "../../components/HeroV2";

import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import ChristopherFoundation from "../../components/ChristopherFoundation";
import StoryBlockSlider from "../../components/StoryBlock/StoryBlockSlider";
import { randomizeArray } from "../../utils";
import { CarBusiness, CarIdentification, CarTransport } from "../../assets/Svg";

const tools = [
  {
    image: require("../../assets/images/carInsurance/help/zaskoleni.jpg").default,
    title: "Zaškolíme vaše zaměstnance",
    text: "Rozšiřte své služby<br/>pro zákazníky",
  },
  {
    image: require("../../assets/images/carInsurance/help/infrastruktura.jpg").default,
    title: "Poskytneme kompletní infrastrukturu pro prodej",
    text: "Statistiky, přehledy <br/>a vyhodnocení v informačním systému, který je přístupný individuálně a online.",
  },
  {
    image: require("../../assets/images/carInsurance/help/provize.jpg").default,
    title: "Získáte zajímavé provize z uzavřených smluv",
    text: "Komplexní zpracování produkce, provizních výpisů podle individuálního provizního modelu.",
  },
  {
    image: require("../../assets/images/carInsurance/help/administrativa.jpg").default,
    title: "Postaráme se <br/>o administrativu a papírování",
    text: "Zajistíme smluvní vztahy a obchodní podmínky se spolupracujícími pojišťovacími zprostředkovateli <br/>a ohlídáme plnění nutných povinností k ČNB.",
  },
];

const questions = [
  {
    icon: CarTransport,
    question: "Zabýváte se prodejem automobilů, či motocyklů?",
  },
  {
    icon: CarIdentification,
    question: "Potkáváte klienty, kteří by mohli mít zájem zejména o autopojištění?",
  },
  {
    icon: CarBusiness,
    question: "Hledáte spolehlivého partnera se zajímavými obchodními podmínkami?",
  },
];

const CarInsurance = ({
  data: {
    wp: {
      page: {
        title,
        featuredImage,
        pageContent: { banner, stepsSection: steps, story, selectedStoriesCarInsurance: stories },
        seo: { metaDesc },
      },
    },
  },
}) => (
  <Layout>
    <SEO title={title} description={metaDesc} />
    <Header variant="light" noNav activeSite={"car"} />

    <HeroHp content={banner} image={featuredImage} />

    <Wrapper>
      <Container justifyContent={"center"} flexWrap={"wrap"}>
        <Flex flexDirection={"column"} flexBasis={["100%", null, null, "35%"]} mr={[null, null, null, 48]}>
          {questions.map(({ icon, question }, index) => (
            <Flex key={index} alignItems={"center"} mb={index + 1 !== questions.length ? 36 : 52}>
              <Image src={icon} width={1} maxWidth={81} mr={30} display="block" />
              <Text fontSize={[18, null, 3]} lineHeight={"23px"} fontWeight={600} color={"headlines"} pr={24}>
                {question}
              </Text>
            </Flex>
          ))}
          <Text fontSize={[1, null, 2]} mb={[4, null, null, 5]}>
            Pokud je odpověď na kteroukoliv otázku výše „ANO“, jste na správné adrese.
          </Text>
        </Flex>
        <Box flexBasis={["100%", null, null, "55%"]} mb={[5, null, null, 0]}>
          <Heading as={"h2"} fontSize={[4, null, null, 5]} lineHeight={"38px"} color={"cta"} mb={[3, null, null, 2]}>
            SPOLUPRACUJTE S KAPITOLEM
          </Heading>
          <Text fontSize={[18, null, 3]} lineHeight={"30px"} mb={3} color={"headlines"}>
            Podnikání v oblasti zprostředkování autopojištění přináší kromě budování vlastní klientely i nezbytné a poměrně složité administrativně-technické
            činnosti. Zajištění těchto činností lze přenechat profesionálům a věnovat se výhradně a plně vlastnímu podnikatelskému záměru.
          </Text>
          <Text fontSize={[1, null, 2]} lineHeight={"24px"} mb={3}>
            Našim spolupracujícím pojišťovacím zprostředkovatelům zajišťujeme komplexní zpracování produkce, provizních výpisů podle individuálního provizního
            modelu, statistiky, přehledy a vyhodnocení.
          </Text>
          <Text fontSize={[1, null, 2]} lineHeight={"24px"} mb={3}>
            Samozřejmostí je zajišťovaní smluvních vztahů a obchodních podmínek se spolupracujícími pojišťovacími zprostředkovateli a plnění nutných povinností
            k&nbsp;ČNB v oblasti jejich registrace.
          </Text>
        </Box>
      </Container>
    </Wrapper>

    <Wrapper>
      <Container flexDirection="column">
        <Heading as="h2" fontSize={[4, null, 28]} lineHeight={0} fontWeight="light" color={"headlines"} uppercase mb={24}>
          Jak vám pomůžeme?
        </Heading>

        <Flex flexWrap="wrap" justifyContent="space-between">
          {tools.map((tool, index) => (
            <Flex flexDirection="column" key={index} width={[1, "calc(50% - 10px)", null, "calc(25% - 12.5px)"]} mb={[5, 4, null, 0]}>
              <Image src={tool.image} display="block" mb={28} width={1} flexOrder={2} borderRadius={1} />
              <Heading
                as="h3"
                fontSize={[18, 3]}
                lineHeight={1}
                fontWeight="regular"
                color={"headlines"}
                mb={3}
                order={[-1, 0]}
                dangerouslySetInnerHTML={{ __html: tool.title }}
              />
              <Text dangerouslySetInnerHTML={{ __html: tool.text }} />
            </Flex>
          ))}
        </Flex>
      </Container>
    </Wrapper>

    {stories && <StoryBlockSlider stories={randomizeArray(stories)} mt={4} mb={4} variant={"blue"} reverse showLeftPane />}

    {steps && <Steps id={"jak-to-funguje"} title={"Jak to funguje?"} steps={steps} variant={"carInsurance"} mt={[4, null, null, 0]} />}

    <Wrapper variant={"carInsurance"} id={"contact-form"} mt={104} borderBottom={"4px solid"} borderBottomColor={"cta"}>
      <Container>
        <Flex flexDirection={["column", null, "row"]} justifyContent={"space-between"} width={1} maxWidth={1118} mt={73} mb={[92, null, 57]}>
          <Flex flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"} mr={[0, null, 4]} mb={[4, null, 0]}>
            <Heading as={"h3"} display={["block", null, "none"]} fontSize={28} lineHeight={"29px"} fontWeight={300} mb={3} uppercase>
              Zaujala vás naše nabídka?
            </Heading>
            <Text display={["block", null, "none"]} mb={4}>
              Domluvte si nezávaznou schůzku a probereme Vaše možnosti.
            </Text>
            <Image
              width={1}
              maxWidth={[285, null, 387]}
              mb={4}
              height={"auto"}
              boxShadow="one"
              borderRadius={1}
              src={require("../../assets/images/contact/contact-hero.jpg").default}
            />
          </Flex>
          <Flex flexDirection={"column"} width={1} maxWidth={["auto", null, null, 610]}>
            <Heading as={"h3"} display={["none", null, "block"]} fontSize={48} lineHeight={"48px"} mb={3}>
              Zaujala vás naše nabídka?
            </Heading>
            <Text display={["none", null, "block"]} mb={4}>
              Domluvte si nezávaznou schůzku a probereme Vaše možnosti.
            </Text>
            <ContactForm type="adviser" title="Kontaktovat poradce" maxWidth={["auto", null, null, 387]} />
          </Flex>
        </Flex>
      </Container>
    </Wrapper>

    <BehindTheScene />

    <Wrapper bg="panelSecondary">
      <ChristopherFoundation
        content={{
          title: "KAPITOL MYSLÍ I&nbsp;NA NAŠE NEJMENŠÍ",
          subTitle:
            "Nadační fond Kryštůfek se&nbsp;zaměřuje na&nbsp;podporu dětských pacientů, kteří se&nbsp;ocitnou v&nbsp;péči záchranářů, a&nbsp;také na&nbsp;edukaci dětí v&nbsp;oblasti poskytování první pomoci. Plyšového kamaráda Kryštůfka záchranáře obdrželo více než 38&nbsp;000 dětí, za&nbsp;což patří velké poděkování všem štědrým dárcům.",
          text:
            "V&nbsp;KAPITOLu, který je zakladatelem a&nbsp;majoritním donorovem nadačního fondu, jsme nesmírně vděční, že&nbsp;podpora jde přímo ke&nbsp;konkrétním dětem. ",
        }}
        pt={[0, null, null, 4]}
        pb={[4, null, null, 64]}
        reverse
      />
    </Wrapper>
  </Layout>
);

export default CarInsurance;

export const pageQuery = graphql`
  query CAR_INSURANCE_PAGE_QUERY {
    wp {
      page(id: "autopojisteni", idType: URI) {
        title
        uri
        pageContent {
          banner {
            fieldGroupName
            title
            titletop
            cta {
              target
              title
              url
            }
            content
          }
          template
          stepsSection {
            heading
            description
          }
          selectedStoriesCarInsurance {
            ...StoryInformation
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
